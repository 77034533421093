














































import Modal from "@/components/Modal.vue";
import { SettingsOrderStatuses } from "@/services/SOLO";
import { Select, Option, Menu, Form } from "element-ui";
import {
  ValidationObserver,
  configure,
  ValidationProvider
} from "vee-validate";
import {
  OrderStatus
} from "@/models";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
  Ref
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from "vuex";
import { eventHandler } from "@/mixins";
import { BForm } from "bootstrap-vue";

interface OrderStatusFormAttributes {
  "delivery-description": string;
  "delivery-description-alt": string;
  "pickup-description": string;
  "pickup-description-alt": string;
}

interface OrderStatusForm {
  id: string;
  attributes: OrderStatusFormAttributes;
}

@Component({
  components: {},
  mixins: [eventHandler]
})
export default class OrderStatusesFormModal extends Vue {
  open: boolean = false;
  submitting: boolean = false;
  $notify: any;
  private statusContainer: OrderStatusForm = {
    id: '',
    attributes: {
      "delivery-description": '',
      "delivery-description-alt": '',
      "pickup-description": '',
      "pickup-description-alt": ''
    }
  }

  $refs!: {
    form: any;
    formValidator: any;
    inputName: any;
  };

  @Prop() private status!: OrderStatusForm;

  constructor() {
    super();
  }

  @Watch('open', {immediate: true, deep: true})
  onOpen(open: Boolean = false) {
    if(open) {
      this.statusContainer.id = this.status.id
      this.statusContainer.attributes['delivery-description'] = this.status.attributes['delivery-description']
      this.statusContainer.attributes['delivery-description-alt'] = this.status.attributes['delivery-description-alt']
      this.statusContainer.attributes['pickup-description'] = this.status.attributes['pickup-description']
      this.statusContainer.attributes['pickup-description-alt'] = this.status.attributes['pickup-description-alt']
    }
  }

  async update(e: any) {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    try {
      const response = await SettingsOrderStatuses.update(this.statusContainer.id, {
        "delivery-description": this.statusContainer.attributes["delivery-description"],
        "delivery-description-alt": this.statusContainer.attributes["delivery-description-alt"],
        "pickup-description": this.statusContainer.attributes["pickup-description"],
        "pickup-description-alt": this.statusContainer.attributes["pickup-description-alt"]
      });

      this.$notify({
        title: "DATA SAVED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Order Status has been updated!",
        type: "success",
        icon: "fas fa-check"
      });

      this.$emit('orderStatus:updated')
      this.closeMe()
    } catch (error) {
      this.submitting = false
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb"
      });
    }
  }

  closeMe() {
    this.open = false
    this.submitting = false
    this.$emit("close")
  }
}
